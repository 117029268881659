var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-breadcrumbs',{staticClass:"pa-0 pb-2",attrs:{"items":_vm.breadcrumbs,"divider":">"}}),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-12",attrs:{"cols":"auto"}},[_c('h1',[_vm._v("Customers")])]),_c('v-col',{staticClass:"pl-12"},[_c('v-text-field',{attrs:{"label":"Search Customer","outlined":"","dense":"","hide-details":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-row',{attrs:{"align":"center","justify":"end","no-gutters":""}},[_c('v-btn',{attrs:{"depressed":"","light":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-archive")]),_vm._v("View Archived ")],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.customers,"no-data-text":"No customers found"},scopedSlots:_vm._u([{key:"item.full_name",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
                name: 'module-eow-customers-individual',
                params: { customerId: item.id },
              }}},[_vm._v(_vm._s(item.full_name))])]}},{key:"item.actions",fn:function({ item }){return [_c('v-btn',{attrs:{"x-small":"","depressed":"","color":"green lighten-4 green--text","to":{
                name: 'module-eow-customers-individual',
                params: { customerId: item.id },
              }}},[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v("mdi-eye")]),_vm._v(" View ")],1)]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }