<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Customers</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Customer"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn depressed light>
              <v-icon left>mdi-archive</v-icon>View Archived
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="tableHeaders"
            :items="customers"
            no-data-text="No customers found"
          >
            <template v-slot:item.full_name="{ item }">
              <router-link
                :to="{
                  name: 'module-eow-customers-individual',
                  params: { customerId: item.id },
                }"
                >{{ item.full_name }}</router-link
              >
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                x-small
                depressed
                color="green lighten-4 green--text"
                :to="{
                  name: 'module-eow-customers-individual',
                  params: { customerId: item.id },
                }"
              >
                <v-icon x-small left>mdi-eye</v-icon> View
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: "eow",
          disabled: false,
          to: { name: "app-dashboard", params: { id: this.$route.params.id } },
        },
        {
          text: "Customers",
          disabled: false,
          exact: true,
          to: {
            name: "module-eow-customers",
            params: { id: this.$route.params.id },
          },
        },
      ],
      searchTerm: "",
      tableHeaders: [
        { text: "Name", value: "first_name" },
        { text: "Email", value: "email" },
        { text: "Actions", value: "actions", align: "end" },
      ],
    };
  },

  computed: {
    customers() {
      let customers = this.$store.getters["eow/customers/all"];

      if (this.searchTerm !== "") {
        customers = customers.filter((c) => {
          const name = c.first_name.toLowerCase();
          const email = c.email.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return name.includes(searchTerm) || email.includes(searchTerm);
        });
      }

      return customers;
    },
  },
};
</script>
